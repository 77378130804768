import React from "react";
import * as s from "./title.module.css";

const Title = ({ children, subtitle, underlined, layout, h1 }) => {
  // console.log(children);
  let customClass = "";
  if (layout === "bolder") {
    customClass = s.featureTitle;
  }
  return (
    <section
      className={`${s.titleBlock} ${customClass} title-block ${underlined ? s.underlined : ""
        }`}
    >
      <div className="container">
        {!!h1 && <h1 className={s.title}>{children}</h1>}
        {!h1 && <h2 className={s.title}>{children}</h2>}

        {!!subtitle && <h4 className={s.subtitle}>{subtitle}</h4>}
      </div>
    </section>
  );
};

export default Title;
