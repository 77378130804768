import Axios from "axios";
import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";

import SearchResult from "./../components/blog/searchResult/searchResult";
import SubscribeBlockBlog from "./../components/blog/subscribeBlockBlog/subscribeBlockBlog";
import Layout from "./../components/layout";
import Loading from "./../components/loading/loading";
import Seo from "./../components/seo";
import Title from "./../components/title/title";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const chunk = require(`lodash/chunk`)

const SearchPage = ({ location }) => {
  const bp = useBreakpoint();

  const [postsChunked, setPostsChunked] = React.useState([]);
  const [totalPostsPages, setTotalPages] = React.useState(0);
  const [showingPosts, setShowingPosts] = React.useState([]);
  const [showingPostsPage, setShowingPostsPage] = React.useState(0);
  const postsPerPage = 9;

  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      startSearch();
    }
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const s = params.get("s");
    startSearch(s);
  }, [])

  const startSearch = async function (s = '') {
    let searchValue = '';
    if (!!s) {
      setSearch(s);
      searchValue = s;
    } else {
      searchValue = search;
    }
    if (!!searchValue) {
      setLoading(true);
      Axios.get("https://wordpress-738825-2656424.cloudwaysapps.com/wp-json/aga/search", { params: { search: searchValue } })
        .then(result => {
          setLoading(false);
          setSearchResults(result?.data?.results);
        })
        .catch(err => {
          setLoading(false);
          console.log("====================================")
          console.log(`Something bad happened while fetching the data\n${err}`)
          console.log("====================================")
        })
    }
  }

  useEffect(() => {
    let postsChunkedIntoArchivePages = chunk(searchResults, postsPerPage);
    setPostsChunked(postsChunkedIntoArchivePages);
    setTotalPages(postsChunkedIntoArchivePages.length);
    if (postsChunkedIntoArchivePages?.length > 0) {
      setShowingPosts(postsChunkedIntoArchivePages[0]);
      setShowingPostsPage(1);
    }
  }, [searchResults]);

  const addShowedPostsByPage = () => {
    let newShowingPosts = [...showingPosts, ...postsChunked[showingPostsPage] ?? []];
    setShowingPosts(newShowingPosts);
    setShowingPostsPage(showingPostsPage + 1);
  }


  return (
    <Layout>
      <Seo title="Search" />
      <div className="container">
        {bp?.lg && <SubscribeBlockBlog />}
        <Title>Search</Title>
      </div>
      {/* <div className="position-relative w-100">
        <input type="text" className={`${s.searchField}`} onKeyDown={handleSearchKeyDown} onChange={(e) => setSearch(e.target.value)} value={search} />
        <button className={`${s.searchButton} h-100`} onClick={() => { startSearch() }}>Search</button>
      </div> */}
      <div className="container">
        <div className={'search-wraper'}>
          <SearchIcon />
          <input type="text" placeholder="Search" name="search" onChange={(e) => setSearch(e.target.value)} value={search} onKeyDown={handleSearchKeyDown} />
        </div>
        {loading ? <div className="py-3 d-flex justify-content-center">
          <Loading size={'90px'} />
        </div> : <>
          {searchResults == null
            ? <div className="py-3 text-center">Start Searching</div>
            : <div className={`row v7articlesWrapper`}>
              {searchResults?.length > 0
                ? <>
                  <div className="col-12">
                    <h3>{searchResults?.length} results found</h3>
                  </div>
                  {showingPosts.map((item, i) => <div className="col-md-6 col-lg-4 mb-3" key={i}><SearchResult item={item} /></div>)}
                  <div className="container">
                    {showingPostsPage < totalPostsPages && <button className="d-block ml-auto exploreBtn mt-3" onClick={() => { addShowedPostsByPage() }}>View More</button>}
                  </div>
                </>
                : <div className="py-3 text-center col-12">No results</div>}
            </div>}
        </>}
      </div>
      {!bp?.lg && <div className="container">
        <SubscribeBlockBlog />
      </div>
      }
    </Layout>
  )
}

export default SearchPage

const SearchIcon = () => {
  return <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7639 21L13.4112 14.7C12.9071 15.1 12.3273 15.4167 11.6718 15.65C11.0164 15.8833 10.3189 16 9.57948 16C7.74763 16 6.19727 15.3708 4.92842 14.1125C3.65957 12.8542 3.02515 11.3167 3.02515 9.5C3.02515 7.68333 3.65957 6.14583 4.92842 4.8875C6.19727 3.62917 7.74763 3 9.57948 3C11.4113 3 12.9617 3.62917 14.2305 4.8875C15.4994 6.14583 16.1338 7.68333 16.1338 9.5C16.1338 10.2333 16.0162 10.925 15.7809 11.575C15.5456 12.225 15.2263 12.8 14.8229 13.3L21.1756 19.6L19.7639 21ZM9.57948 14C10.8399 14 11.9113 13.5625 12.7936 12.6875C13.6759 11.8125 14.1171 10.75 14.1171 9.5C14.1171 8.25 13.6759 7.1875 12.7936 6.3125C11.9113 5.4375 10.8399 5 9.57948 5C8.31903 5 7.24765 5.4375 6.36533 6.3125C5.48302 7.1875 5.04186 8.25 5.04186 9.5C5.04186 10.75 5.48302 11.8125 6.36533 12.6875C7.24765 13.5625 8.31903 14 9.57948 14Z" fill="black" />
  </svg>
}


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
