import React from "react";
import * as s from "./loading.module.css";

const Loading = ({ size }) => {

  let style={}

  if (!!size) {
    style['--size'] = size;
  }

  return (
    <>
      <div className={s.wrapper} style={style}></div>
    </>
  );
};

export default Loading;
