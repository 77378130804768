import { Link } from "gatsby";
import parse from "html-react-parser";
import React from "react";
import * as s from "./searchResult.module.css";
// import { useBreakpoint } from "gatsby-plugin-breakpoints";

const SearchResult = ({ item }) => {
  // const bp = useBreakpoint();
  const post = item;
  return <>
    <article className={`${s.articleItem} h-100`}>
      <div className={`${s.postItem} d-none d-md-flex`}>
        {!!post.image && <img src={post.image} className={`${s.postImg} mw-100`} />}
        <div className={`${s.catDateWrapper} d-flex align-items-center justify-content-between flex-wrap`}>
        {post?.categories?.length > 0 && <div className={`${s.postCategories} mt-0`}>
          {post?.categories?.map((cat) => {
            if ('Blog' == cat?.name) {
              return null
            }
            return <Link to={cat?.uri} key={cat?.uri}>{cat?.name}</Link>
          })}
        </div>}
          <span className={s.postDate}>{item?.date}</span>
        </div>
        <Link to={post?.uri} className={s.postTitle}>
          <h3>{parse(post.title)}</h3>
        </Link>
        <div className={s.postExcerpt}>
          {parse(post.excerpt ?? '')}
        </div>
      </div>
      <div className={`${s.postItem} d-md-none`}>
        <div className="row">
          <div className="col-7 col-sm-7 pr-3">
            <div className="d-flex align-items-center flex-wrap">
              <span className={s.postDate}>{item?.date}</span>
            </div>
            <Link to={post?.uri} className={s.postTitle}>
              <h3>{parse(post.title)}</h3>
            </Link>
          </div>
          <div className="col-5 col-sm-5 pl-1">
            {!!post.image && <img src={post.image} className={`${s.postImg} mw-100`} />}
          </div>
          <div className="col-12">
            {post?.categories?.length > 0 && <div className={`${s.postCategories} mt-0`}>
              {post?.categories?.map((cat) => {
                if ('Blog' == cat?.name) {
                  return null
                }
                return <Link to={cat?.uri} key={cat?.uri}>{cat?.name}</Link>
              })}
            </div>}
          </div>
        </div>
      </div>
    </article>
  </>
}

export default SearchResult;
